import React from 'react'

import { createRoot } from 'react-dom/client'
import 'tailwindcss/tailwind.css'

import Providers from '@app/Providers'
import Router from '@app/Router'
import '@config/i18n'
import '@ui/icons/library'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Providers>
      <Router />
    </Providers>
  </React.StrictMode>
)

// Solution for "SecurityError: The operation is insecure" (see: https://stackoverflow.com/a/64604133/417652)
// TODO: Remove these lines either if we still get the error or if the error is fixed in the future
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then(registration => {
      registration.unregister()
    })
    .catch(error => console.log('ServiceWorker Warning: ', error)) // eslint-disable-line no-console
}
