import React from 'react'

import { BrowserRouter } from 'react-router-dom'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import EntityProvider from '@modules/entities/services/providers/EntityProvider'
import Loading from '@ui/feedback/Loading'

const PublicRouter = React.lazy(() => import('./PublicRouter'))
const PrivateRouter = React.lazy(() => import('./PrivateRouter'))

//
const reactRouterFutureConf = {
  v7_startTransition: true, // Enable experimental startTransition (instead of useState)
  v7_relativeSplatPath: true, // Enable experimental relative splat path
}

/**
 * Main application router.
 * It provides the main router component with the entity context.
 * @returns {React.ReactElement} The router component.
 */
export default function AppRouterProvider() {
  return (
    <BrowserRouter future={reactRouterFutureConf}>
      <EntityProvider>
        <AppRouter />
      </EntityProvider>
    </BrowserRouter>
  )
}

/**
 * Router switcher that renders the public or private router based if the user is authenticated.
 * This needs to be wrapped in a BrowserRouter and EntityProvider. See AppRouterProvider.
 * @returns {React.ReactElement} The router component.
 */
function AppRouter() {
  const { user, loading } = useCurrentUser()

  // Show loading while checking the user
  if (loading) return <Loading />

  // Render the public or private router based on the user
  if (!loading && user) return <PrivateRouter />

  // Otherwise, render the public router
  return <PublicRouter />
}
