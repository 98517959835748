import React from 'react'

import { lowerCase } from 'lodash'

import { reportError } from '@app/services/ClientService'
import Loading from '@ui/feedback/Loading'

/**
 * Error boundary component
 * @typedef {Object} ErrorBoundaryProps
 * @property {React.Component} props.children The children of the component
 * @property {React.Component} props.fallback The Error fallback component
 */

/**
 * @extends {React.Component<ErrorBoundaryProps>}
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, reload: false }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // If the error is a fail to fetch error, we don't want to show the error boundary fallback UI
    if (isFailToFetchError(error)) {
      return { hasError: true, reload: true }
    }

    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // If the error is a fail to fetch error, just reload the page
    if (isFailToFetchError(error)) {
      window.location.reload()
    } else {
      // Otherwise, report the error (via email)
      reportError(error, info, error?.stack, window.location.href)
    }

    console.error(error, info) // eslint-disable-line no-console
  }

  render() {
    // Render custom fallback UI if there was an error and it's not a fail to fetch error
    if (this.state.hasError && !this.state.reload) {
      return this.props.fallback
    }

    if (this.state.reload) {
      console.log('Reloading...') // eslint-disable-line no-console
      window.location.reload() // An extra reload just in case the error wasn't caught
      return <Loading size="lg" />
    }

    return this.props?.children
  }
}

// Error messages that are related to a fail to fetch error
const loadToFailErrors = [
  'Failed to fetch dynamically imported module', // Webkit error message
  'error loading dynamically imported module', // Firefox error message
]

/**
 * Check if the error is a fail to fetch error that are related to a new version of the app being deployed and the user needs to refresh the page.
 * Every new version of the app will have a different hash on the files, so if the user has the app open and a new version is deployed, the app will try to fetch the new files and fail.
 * @param {Error} error The error object
 * @returns {boolean} True if the error is a fail to fetch error, false otherwise
 */
function isFailToFetchError(error) {
  if (!error || error?.name !== 'TypeError') {
    return false
  }

  // Lower case the error message to make it easier to check
  const errorMessage = lowerCase(error.message)

  // Check if the error message is related to a fail to fetch error
  return loadToFailErrors.some(errorText =>
    errorMessage.includes(lowerCase(errorText))
  )
}
