import React from 'react'

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useId,
  useMergeRefs,
  useTransitionStatus,
} from '@floating-ui/react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CloseButton from '@ui/buttons/CloseButton'
import useClickable from '@ui/helpers/Clickable/useClickable'

import { useDialogContext } from './hooks'

/**
 * The styles for the dialog variants
 * @typedef {'base'|'warn'|'danger'} DialogVariant The DialogContent variant
 */
const variantStyles = {
  base: {
    header: 'border-b border-gray-400',
  },
  warn: {
    header: 'border-b-2 border-warn-400',
  },
  danger: {
    header: 'border-b-4 border-danger-500',
  },
}

/**
 * @typedef {import('@ui/buttons/Button').ButtonVariant} ButtonVariant
 *
 * @typedef {Object} DialogContentPropTypes The props of the DialogContent component
 * @property {React.ReactNode|Function} children The content of the dialog  (can be a function that receives the `setOpen` and `open` props)
 * @property {String} title The title of the dialog
 * @property {String} kicker The kicker text displayed above the title
 * @property {String} description The description displayed below the title
 * @property {React.ReactNode} buttons Sets the buttons for the dialog
 * @property {String} [childrenClass=''] Extra classes for the children container
 * @property {String} [buttonsClass=''] The class name buttons.
 * @property {Boolean} showOkButton Whether to show an OK button.
 * @property {ButtonVariant} okButtonVariant The variant of the OK button.
 * @property {String} [boxClass=''] Extar classes for the box.
 * @property {String} [overlayClass=''] Extra classes for the overlay.
 * @property {Function} getBoxClass A function to obtain a class for the box.
 * @property {Function} getOverlayClass A function to obtain a class for the overlay.
 * @property {Boolean} showCancelButton Whether to show a cancel button.
 * @property {Boolean} showOkButtonIcon Whether to show an icon in the ok button.
 * @property {Boolean} showCancelButtonIcon Whether to show an icon in the cancel button.
 * @property {String} cancelButtonIcon Icon to show in cancel button. A default 'times' icon will be used if not provided any other value
 * @property {String} okButtonIcon Icon to show in ok button. A default 'check' icon will be used if not provided any other value
 * @property {String} okButtonLabel The label for the OK button.
 * @property {Function} onOkClick The function to call when the OK button is clicked.
 * @property {Function} onCancel The function to call when the Cancel button is clicked.
 * @property {DialogVariant} [variant='base'] The variant of the dialog.
 * @property {React.Ref} ref A ref to the Dialog content.
 */

/**
 * DialogContent is a component that renders the content of a Dialog.
 *
 * @type {React.ForwardRefRenderFunction<HTMLElement, DialogContentPropTypes>} React component
 */
const DialogContent = React.forwardRef(function DialogContent(props, propRef) {
  const { t } = useTranslation()
  const {
    title,
    kicker,
    description,
    children,
    childrenClass = '',
    buttons,
    buttonsClass = '',
    boxClass = '',
    getBoxClass,
    overlayClass = '',
    getOverlayClass,
    showCancelButton,
    cancelButtonIcon = 'times',
    showOkButton,
    showOkButtonIcon,
    showCancelButtonIcon,
    okButtonLabel,
    okButtonVariant,
    okButtonDisabled,
    onOkClick,
    okButtonIcon = 'check',
    onCancel,
    variant = 'base',
    ...rest // The rest of the props are passed to the getFloatingProps function of the floating context
  } = props

  const {
    context: floatingContext,
    setLabelId,
    setDescriptionId,
    open,
    setOpen,
    ...context
  } = useDialogContext()

  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const { isMounted, status } = useTransitionStatus(floatingContext)
  const isOpen = status === 'open'

  const labelId = useId()
  const descriptionId = useId()

  const variantClasses = variantStyles[variant] || variantStyles.base

  // Only sets `aria-labelledby` on the Dialog root element if this component is mounted inside it.
  React.useLayoutEffect(() => {
    if (title) setLabelId(labelId)
    if (description) setDescriptionId(descriptionId)
    return () => setLabelId(undefined)
  }, [title, labelId, setLabelId, description, descriptionId, setDescriptionId])

  // Handles the OK button click event
  const handleOkClick = React.useCallback(() => {
    if (typeof onOkClick === 'function') onOkClick()
    setOpen(false)
  }, [onOkClick, setOpen])

  const overlayClasses =
    typeof getOverlayClass === 'function'
      ? getOverlayClass(isMounted)
      : overlayClass

  const boxClasses =
    typeof getBoxClass === 'function' ? getBoxClass(isMounted) : boxClass

  const clickableProps = useClickable({
    onClick: e => e.stopPropagation(), // This prevents the click event on the dialog content to bubble up to the ancestor components. An example of this when a Dialog is used inside of a Collapse Panel component, and Panel is closed when the Dialog content is clicked.
    withClassName: false, // We don't need any clickable class for this
  })

  if (!isMounted) return null

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={`fixed inset-0 flex items-center justify-center bg-black/50 transition-all duration-500 ease-in-out ${
          isOpen
            ? 'z-max opacity-100 backdrop-blur-sm'
            : 'pointer-events-none z-0 opacity-0 backdrop-blur-0'
        } ${overlayClasses}`}
        lockScroll={isOpen}
      >
        <FloatingFocusManager context={floatingContext} modal={isOpen}>
          <div
            {...context.getFloatingProps(rest)}
            className={`flex max-h-[90vh] transform-gpu flex-col rounded-lg bg-white transition-all duration-500 ease-in-out ${
              isOpen
                ? 'translate-y-0 opacity-100 shadow-xl drop-shadow-2xl'
                : 'translate-y-12 opacity-0 shadow-none drop-shadow-none'
            } ${boxClasses}`}
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...clickableProps}
          >
            <div
              className={`relative shrink-0 border-b p-6 pb-4 ${variantClasses.header || ''}`}
            >
              <div className="flex flex-col items-stretch gap-1">
                {kicker && (
                  <strong className="text-sm font-bold uppercase text-gray-500">
                    {kicker}
                  </strong>
                )}
                {title && (
                  <h2 className="text-xl font-semibold leading-5" id={labelId}>
                    {title}
                  </h2>
                )}
                {description && (
                  <div className="text-sm text-gray-400" id={descriptionId}>
                    {description}
                  </div>
                )}
              </div>

              <div className="absolute -right-3 -top-3">
                <CloseButton
                  onClick={() => {
                    onCancel?.()
                    setOpen(false)
                  }}
                  variant="solid"
                />
              </div>
            </div>
            <div
              className={`max-h-[80vh] overflow-y-scroll p-6 shadow-inner hide-scrollbar ${childrenClass}`}
            >
              {typeof children === 'function'
                ? children({ setOpen, open })
                : children}
            </div>
            {(buttons !== undefined || showOkButton) && (
              <div className="flex shrink-0 items-center justify-between gap-4 border-t px-6 py-4">
                <div
                  className={`flex flex-grow items-center justify-between gap-4 ${buttonsClass}`}
                >
                  {showCancelButton || showOkButton ? (
                    <>
                      {showCancelButton && (
                        <Button
                          label={t('cancel')}
                          onClick={() => {
                            onCancel?.()
                            setOpen(false)
                          }}
                          icon={
                            showCancelButtonIcon ? cancelButtonIcon : undefined
                          }
                        />
                      )}
                      {showOkButton && (
                        <Button
                          label={okButtonLabel || t('ok')}
                          onClick={handleOkClick}
                          icon={showOkButtonIcon ? okButtonIcon : undefined}
                          variant={okButtonVariant || variant || 'primary'}
                          disabled={okButtonDisabled}
                        />
                      )}
                    </>
                  ) : typeof buttons === 'function' ? (
                    buttons({ setOpen, open })
                  ) : (
                    buttons
                  )}
                </div>
              </div>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export default DialogContent
